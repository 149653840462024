$yellow: #eeff00;

@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../node_modules/bootstrap-block-grid/src/bootstrap3-block-grid";
@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/slick-carousel/slick/slick-theme";

@font-face {
  font-family: 'Agency';
  src: url('fonts/Agency_FB.ttf') format('truetype')
}

@font-face {
  font-family: 'Geogrotesque';
  src: url('fonts/GeogrotesqueRegular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Geogrotesque';
  src: url('fonts/GeogrotesqueLight.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Geogrotesque';
  src: url('fonts/GeogrotesqueMedium.ttf') format('truetype');
  font-weight: 600;
}

body {
  font-family: 'Geogrotesque', sans-serif;
  background: black url('img/bg.jpg') repeat-y;
  background-size: 100% auto;
  color: #fff;
  font-size: 18px;
  letter-spacing: .1em;
}

.img-responsive {
  @media (min-width: 1921px) {
    width: 100%;
  }
}

.header-video {
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px;
  @media (min-width: $screen-sm-min) {
    padding: 40px;
  }
  .embed-responsive {
    border: solid 2px $yellow;
  }
}

.gallery-title {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .3em;
  span {
    color: $yellow;
  }
}

.img-gallery-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  @media (min-width: $screen-sm-min) {
    padding: 40px;
  }
  .img-gallery {
    border: solid 2px $yellow;
  }
  .prev, .next {
    position: absolute;
    display: block;
    line-height: 1;
    font-size: 40px;
    cursor: pointer;
    background: transparent;
    color: white;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
}

.footer {
  background: $yellow;
  padding: 40px;
  text-align: center;
  border-radius: 0;
  h1 {
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 0.2em;
    margin: 0;
    color: #222;
    @media (min-width: $screen-lg-min) {
      font-size: 70px;
      letter-spacing: 0.3em;
    }
    @media (min-width: $screen-sm-min) {
      font-size: 40px;
      letter-spacing: 0.3em;
    }
  }
}

.agency-font {
  font-family: 'Agency', sans-serif;
  font-weight: bold;
}

.font-medium {
  font-weight: 600;
}

.font-light {
  font-weight: 300;
}

.section-title {
  font-size: 55px;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: $yellow;
  @media (min-width: $screen-sm-min) {
    font-size: 102px;
  }
  small {
    display: block;
    font-size: 18px;
    letter-spacing: .6em;
    color: $yellow;
    @media (min-width: $screen-sm-min) {
      font-size: 21px;
    }
  }
}

.features {
  font-size: 21px;
  letter-spacing: .6em;
  text-transform: uppercase;
  color: $yellow;
  margin-bottom: -10px;
  margin-top: 40px;
  font-weight: bold;
}

hr {
  border-color: #636363;
  border-width: 3px;
}

.text-center {
  img {
    margin: 0 auto;
  }
}

.has-row-title {
  position: relative;
}

.row-title {
  @media (min-width: $screen-md-min) {
    position: absolute;
    top: 130px;
    left: -20px;
    transform: rotate(-90deg);
    transform-origin: top left 0;
    float: left;
    margin: 0;
  }
  margin: 0 0 10px 15px;
  text-transform: uppercase;
  font-size: 21px;
  letter-spacing: .1em;
}

.feature-name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: $yellow;
  margin-bottom: 2px;
}

.feature-description {
  font-size: 14px;
  letter-spacing: .025em;
  font-weight: 300;
  line-height: 1.26;
}

.colorways {
  @media (min-width: $screen-md-min) {
    margin: 0 40px 0 90px;
  }
}

.colorway {
  margin-top: 60px;
  font-size: 15px;
  letter-spacing: .05em;
  font-weight: 600;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    margin-right: 100px;
    white-space: nowrap;
  }
}

.product-name {
  color: $yellow;
  font-weight: 600;
  font-size: 33px;
  letter-spacing: .05em;
}

.bullets {
  font-size: 19px;
  letter-spacing: .025em;
  margin-top: 30px;
  font-weight: 600;
  margin-left: 20px;
  p span {
    margin-left: -20px;
  }
}

.image-fit {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: #FFF;
  img {
    position: absolute;
    max-width: 95%;
    max-height: 95%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-150 {
  margin-top: 150px;
}

.m-t-n100 {
  @media (min-width: $screen-lg-min) {
    margin-top: -100px;
  }
}

.m-t-l100 {
  @media (min-width: $screen-lg-min) {
    margin-top: 100px;
  }
}

.m-b-10 {
  margin-bottom: 10px;
}

.p-r-100 {
  padding-right: 100px;
}

.p-r-175 {
  padding-right: 175px;
}